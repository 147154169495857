html,
body {
  margin: 0;
  color: #4B505A;
  background-color: #F5F5F5;
}

#App {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: normal;
  word-spacing: 0px;
}

/* supprimer encadre bleu menu */
.MuiList-root.bm-item.MuiList-dense:focus {outline:0;} 

#page-wrap {
  margin: 0% 20%;
  text-align: justify;
  text-justify: inter-word;
  background-color:#FFFFFF;
  /* Prevent sidebar from showing a scrollbar on page */
  overflow: auto;
  margin-bottom: 20%;
}

.article > h1 {
  font-size: 280%; 
  font-family: "Proxima Nova Soft";
  text-align: center;
}

/*Size for mobile*/
@media (max-width: 1024px) {
  #page-wrap {
    margin: 0 4%;
    margin-bottom: 10%;
  }
  .article > h1 {
    font-size: 210%;
    font-family: "Proxima Nova Soft";
    text-align: center;
  }
  .article > h2 {
    font-size: 140%;
    text-align: left;
    color: #333333;
  }

  ol, ul { 
    padding: 15px;
  }
  #sneakymercury{
    font-size: 220%;
  }
  .sneaky_article > h2{
    font-size: 160%;
    font-weight: bold;
    text-align: left;
    color: #333333;
  }
  .sneaky_article > h3{
    font-size: 130%;
    text-align: left;
    color: #333333;
  }
}

a {
  text-decoration: none;
  color: #783201;
}

a:hover {
font-weight: bold;
}

#openclassrooms a {
  color: #9013FE;
}

.tabulation {
  text-indent: 20px;
}

.indent {
  margin-left: 35%;
}

img:not(#loading) {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #F5F5F5;
  margin: 1em 0;
  padding: 0;
}

.parchemin {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #783201;
  margin: 1em 0;
  padding: 0;
}

.article{
  margin: 0 5%;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed; width: 30px; height: 26px; left: 36px; top: 36px;
}

@media (max-width: 1024px) {
  .bm-burger-button {
    width: 16px;
    height: 16px;
    left: 7px;
    top: 10px;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #121212;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  left: 70%;
}

@media (max-width: 1024px) {
  .bm-cross-button {
    left: 50%;
  }
}

/* Color/shape of close button cross */
.bm-cross {
  background:#bdc3c7;
}

/* General sidebar styles */
.bm-menu {
  background: #121212;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  width: 82%;
}

@media (max-width: 1024px) {
  .bm-menu {
    width: 62%;
  }
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #121212;
  
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  border: none;
}

.site-footer {
  position: fixed;
  left:0;bottom:0;right:0;
  text-align: center;
  padding: 0.6%;
  width: 100%;
  color: #F5F5F5;
  background-color: #333333;
}

#terminal {
  color: white;
  margin: 40px;
  background-color:#333333;
  padding: 20px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

#search {
  margin-left: 800px;
}

#hackerman_img {
  width: 40%;
}

#pentester_img {
  width: 50%;
}

#whitebox_img {
  width: 70%;
}

#diagram_img {
  width: 95%;
}

#openclassrooms_img{
  height: 40%;
  width: 40%;
}

#hackthebox_img {
  width:60%; 
}

#cerbere_img {
  width:60%; 
}

#principe_kerberos {
  width:80%; 
}

#activedirectory_img {
  width:60%; 
}

#laps {
  width:50%; 
}

#nsa{
  width:25%; 
}
#fuzzbunch{
  width:80%; 
}
#eternalblue_img {
  width:60%; 
}
#help {
  width:60%; 
}
#demo {
  width:60%; 
}
#interactif {
  width:60%; 
}
#flow {
  width:90%; 
}
#eternalbluesuccess{
  width: 60%;
}
#kaliraspberry{
  width: 90%;
}
#hacktheplanet{
  width: 70%;
}
#methodoredteam{
  width: 90%;
}
#hide_raspberry{
  width: 80%;
}
#raspap{
  width: 35%;
}
#instance{
  width: 90%;
  border: 0.5px solid;
}
#firewall {
  width: 90%;
}
#publique {
  width: 90%;
}
#autologin {
  width: 50%;
}
#redteamuser {
  width: 90%;
}
#schema_ntlm {
  width: 80%;
}
#golden_ticket {
  width: 60%;
}
#silver_ticket {
  width: 60%;
}
#radius01 {
  width: 80%;
}
#radius02 {
  width: 80%;
}

@media (max-width: 1024px) {
  #hackerman_img {
    width: 80%;
  }
  #openclassrooms_img {
    height: 80%;
    width: 80%;
  }
  #anonymous_img {
    width: 80%;
  }
  #hackthebox_img {
    width: 80%; 
  }
  #cerbere_img {
    width:80%; 
  }
  #principe_kerberos {
    width:105%; 
  }
  #activedirectory_img {
    width:100%; 
  }
  #laps {
    width:90%; 
  }
  #nsa{
    width:50%; 
  }
  #fuzzbunch{
    width:100%; 
  }
  #eternalblue_img {
    width:100%; 
  }
  #help{
    width:100%; 
  }  
  #demo {
    width:100%; 
  }
  #interactif {
    width:100%; 
  }
  #flow {
    width:105%; 
  }
  #eternalbluesuccess{
    width: 100%;
  }
  #raspap{
    width: 100%;
  }
}